/* eslint-disable global-require */
import moment from 'moment'

export default {
  methods: {
    timeStampToDate(value) {
      const locale = localStorage.getItem('locale') || process.env.VUE_APP_LOCALE
      let dateConvert = new Date(value * 1000)
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      // dateConvert = dateConvert.setMonth(dateConvert.getMonth() + 1)
      // dateConvert = dateConvert.toDateString()
      dateConvert = dateConvert.toLocaleDateString(locale === 'vi' ? 'vi-VN' : 'en-US', options)
      return dateConvert
    },

    formatCalendarDate(date) {
      if (!date) return ''
      return moment(new Date(date)).format('YYYY-MM-DD')
    },

    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },

    calculateDateBetweenTwoDays(end, start) {
      if (end) {
        const date1 = new Date(end * 1000)
        const date2 = start ? new Date(start * 1000) : new Date()

        const difference = date1.getTime() - date2.getTime()
        return Math.ceil(difference / (1000 * 3600 * 24))
      }
      return 0
    },
  },
}
