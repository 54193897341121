/* eslint-disable global-require */

export default {
  methods: {
    currencyFormat(value, currency = 'USD') {
      const val = parseFloat(value)
      let minimumFractionDigits = 0
      if (Number.isInteger(val) || val === 0 || !val) {
        minimumFractionDigits = 0
      } else {
        minimumFractionDigits = 2
      }
      return (parseFloat(value) || 0).toLocaleString('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits,
      })
    },

    localStringFormat(value) {
      return parseFloat(value || 0).toLocaleString('en-US')
    },

    truncDigits(num) {
      // eslint-disable-next-line no-underscore-dangle
      const _num = String(num)
      if (_num.indexOf('.') !== -1) {
        const numarr = _num.split('.')
        if (numarr.length === 1) {
          return Number(_num)
        }

        return Number(`${numarr[0]}.${numarr[1].charAt(0)}${numarr[1].charAt(1)}`)
      }

      return Number(_num)
    },

    amountFormat(amount) {
      return Math.floor(amount * 100) / 100
    },

    validateEmail(str) {
      if (!str) return false
      // eslint-disable-next-line no-useless-escape
      return /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(str)
    },
  },
}
