<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-body class="p-md-1">
      <div class="title-card">
        <div class="title-content">
          <b-card-title class="font-20 font-weight-700 m-0">
            {{ $t('partnerView.textTotalUsers', {typeSort: typeSort === 'day' ? $t('partnerView.textMonthly') : $t('partnerView.textYearly') }) }}
          </b-card-title>
        </div>
        <div class="select-date">
          <analytics-selection
            :selection="selection"
            @change="handleSelectionChange"
          />
        </div>
      </div>
      <p
        v-if="errorGetUserAnalytics"
        class="text-danger"
      >
        {{ errorGetUserAnalytics }}
      </p>
      <div v-else>
        <app-echart-line :option-data="option" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardTitle } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import store from '@/store'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import AnalyticsSelection from './AnalyticsSelection.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    AppEchartLine,
    AnalyticsSelection,
  },

  mixins: [dateTimeFormatMixin],

  data() {
    return {
      option: {
        legend: {
          data: [this.$t('partnerView.textUserActivate'), this.$t('partnerView.textUserBlock')],
        },
        xAxisData: [],
        series: [
          {
            name: this.$t('partnerView.textUserActivate'),
            type: 'line',
            data: [],
            lineStyle: {
              color: '#1DB9C3',
            },
            itemStyle: {
              color: '#1DB9C3',
            },
            smooth: true,
          },
          {
            name: this.$t('partnerView.textUserBlock'),
            type: 'line',
            data: [],
            lineStyle: {
              color: '#FBB03BB2',
            },
            itemStyle: {
              color: '#FBB03BB2',
            },
            smooth: true,
          },
        ],
      },
      selection: {
        type: 'daily',
        monthSelected: new Date().getMonth() + 1,
        yearSelected: new Date().getFullYear(),
      },

      errorGetUserAnalytics: '',
    }
  },

  computed: {
    ...mapGetters(['blockedUsers', 'activedUsers', 'message', 'status']),
    ...mapGetters(['typeSort']),
    params() {
      if (this.selection.type === 'daily') {
        return {
          year: this.selection.yearSelected,
          type: this.selection.type,
          month: this.selection.monthSelected,
        }
      }

      return {
        year: this.selection.yearSelected,
        type: this.selection.type,
      }
    },
  },

  watch: {
    activedUsers() {
      this.setData()
    },
    blockedUsers() {
      this.setData()
    },
  },

  created() {
    this.createdComponent()
  },

  methods: {
    ...mapActions(['getUserAnalytics']),

    async createdComponent() {
      store.commit('referralProgram/SET_TYPE_USER_SORT', 'day')
      store.commit('referralProgram/SET_LIMIT_DAY_USER', new Date().getDate())
      store.commit(
        'referralProgram/SET_LIMIT_MONTH_USER',
        new Date().getMonth() + 1,
      )

      if (this.selection.type === 'daily') {
        this.setMonthly(new Date().getFullYear(), new Date().getMonth() + 1)
      } else {
        this.setYearly()
      }

      this.setData()
      await this.getUserAnalytics(this.params)
      if (!this.status) {
        this.errorGetUserAnalytics = this.message
      }
    },

    setYearly() {
      this.option = {
        ...this.option,
        xAxisData: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      }
    },

    setData() {
      this.option = {
        ...this.option,
      }

      // Active item
      this.option.series[0].data = this.activedUsers
      // Block item
      this.option.series[1].data = this.blockedUsers
    },

    setMonthly(year = '2022', month = new Date().getMonth() + 1) {
      const daysInMonth = this.getDaysInMonth(year, month)

      const xAxisData = []
      for (let index = 1; index <= daysInMonth; index += 1) {
        xAxisData.push(index)
      }

      this.option = {
        ...this.option,
        xAxisData,
      }
    },

    handleSelectionChange(data) {
      if (data.type === 'daily') {
        this.setMonthly(data.year, data.month)
        store.commit('referralProgram/SET_TYPE_USER_SORT', 'day')
      } else {
        this.setYearly()
        store.commit('referralProgram/SET_TYPE_USER_SORT', 'month')
      }

      let limitDay = 0
      if (data.year < new Date().getFullYear()) {
        limitDay = this.getDaysInMonth(data.year, data.month)
      } else {
        limitDay = data.month < new Date().getMonth() + 1
          ? this.getDaysInMonth(data.year, data.month)
          : new Date().getDate()
      }
      const limitMonth = data.year < new Date().getFullYear() ? 12 : new Date().getMonth() + 1
      store.commit('referralProgram/SET_LIMIT_DAY_USER', limitDay)
      store.commit('referralProgram/SET_LIMIT_MONTH_USER', limitMonth)
      setTimeout(() => {
        this.getUserAnalytics(data)
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-statistics {
  padding: 16px 16px 8px;
  border-radius: var(--border-radius-base);

  .title-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .title-content {
      @media (max-width: 767px) {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
