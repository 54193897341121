<template>
  <b-row
    class="
      bg-white
      top-content"
  >
    <b-col
      lg="6"
      cols="12"
      class="px-0"
    >
      <h3>
        {{ $t('referral.tabEarnMoney.labelReferralLink') }}
      </h3>
      <span class="font-14 mt-1">
        {{ $t('partnerView.textShareThisLink') }}
      </span>
    </b-col>
    <b-col
      class="mt-1 mt-md-0 px-0 d-md-flex align-items-center"
      lg="6"
      cols="12"
    >
      <b-form-input
        v-model="referralCode"
        id="readOnlyInput"
        class="input-height"
        readonly
      />
      <div
        class="btn-group ml-md-1 mt-1 mt-md-0"
      >
        <btn-loading
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="rounded-pill"
          variant-convert="outline-secondary"
          :disabled="copySuccess"
          @click="handleCopy"
        >
          {{ !copySuccess ? $t('partnerView.textCopyUrl') : $t('partnerView.textUrlCopied') }}
        </btn-loading>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBModal,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import Ripple from 'vue-ripple-directive'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  components: {
    BtnLoading,
    BFormInput,
    BRow,
    BCol,
  },

  data() {
    return {
      copySuccess: false,
    }
  },

  computed: {
    ...mapGetters(['user']),

    referralCode() {
      return `${process.env.VUE_APP_LOGIN_URL}/register?refcode=${this.user?.data?.referralTracking?.code}`
    },
  },

  methods: {
    handleCopy() {
      this.$copyText(this.referralCode)
        .then(() => {
          this.copySuccess = true
        })
      setTimeout(() => {
        this.copySuccess = false
      }, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
.input-height{
  height: 40px
}

.btn-group{
  .rounded-pill{
    padding: 9px 21px;
    width: max-content;

    &:hover{
      background: rgba(22, 33, 62, 0.1);
    }
  }
}
.top-content {
  border-radius: var(--border-radius-md);
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  padding: 32px;
  justify-content: space-between;
  margin: 0 0 24px 0;

  @media(max-width: 767px) {
    padding: 20px;
  }
}
</style>
