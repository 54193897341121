<template>
  <div class="metrics-dashboard">
    <b-row class="card-metrics">
      <b-col
        v-for="(item, index) in monthlyAnalytics"
        :key="index"
        md="4"
        sm="12"
        class="mb-1 mb-md-0"
      >
        <div class="top-content">
          <div>
            <span class="font-14">{{ item.label }}</span>
            <feather-icon
              v-b-tooltip.v-light="item.des"
              icon="HelpCircleIcon"
              size="11"
              style="margin-top: -10px"
            />
            <b-spinner
              v-if="loading"
              class="d-block"
              small
            />
            <h2 v-else>
              {{ item.value }}
            </h2>
          </div>
          <b-img
            :src="item.icon"
            fluid
            alt="Responsive image"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  BRow, BCol, VBTooltip, BImg, BSpinner,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BSpinner,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['monthlyAnalytics', 'loading']),
  },
}
</script>
<style lang="scss" scoped>
.metrics-dashboard {
  .card-metrics {
    margin-bottom: 24px;
    .top-content {
      background: #fff;
      border-radius: var(--border-radius-md);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px;

      @media(max-width: 767px) {
        padding: 20px;
      }
    }
  }
}
</style>
