<template>
  <div class="total-deposit-container">
    <div class="d-md-flex align-items-center">
      <p
        v-if="errorGetDeposit"
        class="text-danger"
      >
        {{ errorGetDeposit }}
      </p>
      <div
        v-else
        class="d-flex align-items-center justify-content-center"
      >
        <b-card-text class="m-0 font-14">
          {{ $t('partnerView.textMonthlySpending') }}:
        </b-card-text>
        <b-card-title class="font-24 mb-0 ml-1">
          ${{ amountFormat(monthlySpending) }}
        </b-card-title>
      </div>
      <div class="ml-md-2 mt-1 mt-md-0 d-flex justify-content-center">
        <b-form-select
          v-model="monthSelected"
          :options="monthOptions"
          class="month-selection input-height mr-1"
          @input="handleSelectOption"
        />
        <b-form-select
          v-model="yearSelected"
          :options="yearOptions"
          class="year-selection input-height"
          @input="handleSelectOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BCardTitle, BCardText, BFormSelect } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import numberFormatMixin from '@/mixins/numberFormatMixin'

const { mapActions, mapGetters } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BCardTitle,
    BCardText,
    BFormSelect,
  },

  mixins: [numberFormatMixin],

  props: {
    errorGetDeposit: {
      type: String,
      default: '',
    },
  },

  data() {
    return {

      monthSelected: new Date().getMonth() + 1,
      yearSelected: new Date().getFullYear(),
    }
  },

  computed: {
    ...mapGetters(['deposits', 'status', 'message']),

    monthlySpending() {
      return _get(this.deposits, ['monthlySpending'], 0)
    },

    monthOptions() {
      const data = []
      let i = 1
      while (i <= 12) {
        data.push({
          value: i,
          text: i,
          disabled:
            i > new Date().getMonth() + 1
            && this.yearSelected >= new Date().getFullYear(),
        })

        i += 1
      }

      return data
    },

    yearOptions() {
      const data = []
      let i = 2021
      while (i <= new Date().getFullYear()) {
        data.push({
          value: i,
          text: i,
        })
        i += 1
      }

      return data
    },
  },

  methods: {
    ...mapActions(['getDeposits']),

    handleSelectOption() {
      if (this.monthSelected > new Date().getMonth() + 1
        && this.yearSelected >= new Date().getFullYear()) {
        this.monthSelected = 1
      } else {
        this.handleSelect()
      }
    },

    async handleSelect() {
      const params = {
        month: this.monthSelected,
        year: this.yearSelected,
        totalSpending: 1,
        totalDeposit: 1,
        monthlySpending: 1,
        monthlyDeposit: 1,
      }

      await this.getDeposits(params)
    },
  },
}
</script>

<style lang="scss" scoped>
.total-deposit-container {
  padding: 32px;
  margin-bottom: 24px;
  background: #ffffff;
  border-radius: var(--border-radius-md);

  @media(max-width: 767px) {
    padding: 20px;
  }

  .input-height{
    height: 40px;
  }
}
</style>
