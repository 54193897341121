<template>
  <div>
    <referral-link />
    <metrics-dashboard />
    <total-deposit :error-get-deposit="errorGetDeposit" />
    <statistics-total-user />
    <!--    <statistics-plan-charge />-->
  </div>
</template>
<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import ReferralLink from './components/ReferralLink.vue'
import MetricsDashboard from './components/MetricsDashboard.vue'
// import StatisticsPlanCharge from './components/StatisticsPlanCharge.vue'
import StatisticsTotalUser from './components/StatisticsTotalUser.vue'
import TotalDeposit from './components/TotalDeposit.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    ReferralLink,
    MetricsDashboard,
    // StatisticsPlanCharge,
    StatisticsTotalUser,
    TotalDeposit,
  },

  data() {
    return {
      errorGetDeposit: '',
      errorGetUserAnalytics: '',
    }
  },

  computed: {
    ...mapGetters(['message', 'status']),
  },

  created() {
    this.getDataDashboard()
  },

  methods: {
    ...mapActions(['getAdsAccounts', 'getDeposits']),

    async getDataDashboard() {
      const params = {
        month: moment().month() + 1,
        year: moment().year(),
        totalSpending: 1,
        totalDeposit: 1,
        monthlySpending: 1,
        monthlyDeposit: 1,
      }
      // await this.getAdsAccounts({ fromDate: moment().startOf('month').format('YYYY-MM-DD'), toDate: moment().endOf('month').format('YYYY-MM-DD') })
      await this.getDeposits(params).then(() => {
        this.errorGetDeposit = this.message
      })
      await this.getAdsAccounts().then(() => {
        this.errorGetUserAnalytics = this.message
      })
      // await this.getDeposits()
    },
  },
}

</script>
<style lang="scss" scoped>

</style>
